import {
  getActiveOrganizationType,
  getActiveRolePermission,
} from '@/redux/slice/authSlice';
import { getAllRoles } from '@/redux/slice/roleAndPermissionSlice';
import {
  ORGANIZATION_TYPES,
  PRIVATE_NAVIGATION,
  PUBLIC_NAVIGATION,
} from '@/utils/constants';
import { useSelector } from 'react-redux';

export const useRolePermission = ({
  featureName,
  permissionNameArray,
  organizationType,
}: {
  featureName?: string | boolean;
  permissionNameArray?: string | boolean;
  organizationType?: string | boolean;
}) => {
  const RolePermissions = useSelector(getActiveRolePermission);
  const activeOrganization = useSelector(getActiveOrganizationType);
  let checkOrganization = false;
  if (organizationType === true) {
    checkOrganization = true;
  }
  if (typeof organizationType == 'string') {
    checkOrganization =
      organizationType?.split(',').findIndex((organizationTypeSingle) => {
        return organizationTypeSingle == activeOrganization;
      }) > -1;
  }

  if (organizationType === undefined || checkOrganization) {
    if (featureName == undefined || permissionNameArray == undefined) {
      return false;
    }
    if ((permissionNameArray === featureName) === true) {
      return true;
    }
    if (permissionNameArray === false && featureName === false) {
      return false;
    }
    if (typeof permissionNameArray === 'string' && typeof featureName === 'string') {
      const checkPermission = permissionNameArray
        .split(',')
        .findIndex((permissionName) => {
          return RolePermissions?.[featureName]?.includes(permissionName);
        });
      if (checkPermission === undefined) {
        return false;
      }
      return checkPermission > -1;
    }
  }
};

export const getNavigationOptions = () => {
  const navOptions1 = [
    {
      id: 1,
      name: 'Home',
      to: PRIVATE_NAVIGATION.home.path,
    },
    {
      id: 2,
      name: 'Projects',
      to: PRIVATE_NAVIGATION.projects.manage.path,
      organization: ORGANIZATION_TYPES.NODE,
    },
    {
      id: 3,
      name: 'Users',
      to: PRIVATE_NAVIGATION.users.list.path,
      permission: true,
    },
    {
      id: 4,
      name: 'Network',
      to: PRIVATE_NAVIGATION.organization.list.path,
      organization: `${ORGANIZATION_TYPES.PARTNER},${ORGANIZATION_TYPES.NETWORK_MASTER}`,
    },
  ].filter((route) => {
    if (route?.organization) {
      return useRolePermission({
        featureName: true,
        permissionNameArray: true,
        organizationType: route?.organization,
      });
    }
    return true;
  });

  const navOptions2 = [
    {
      id: 5,
      name: 'Guide',
      to: 'https://trudenty.notion.site/Consumer-Trust-Network-Sandbox-Guide-174a0212ddd080d4b593d478ac5f06d2?pvs=74',
      isExternal: true,
    },
    {
      id: 6,
      name: 'Docs',
      to: PUBLIC_NAVIGATION.readmeDocs,
      isExternal: true,
    },
    {
      id: 7,
      name: 'Support',
      to: PRIVATE_NAVIGATION.contactSupport.path,
    },
  ];

  return { navOptions1, navOptions2 };
};

export const useRoleByOrganizationType = (organization_type: string) => {
  const RolePermissions = useSelector(getAllRoles);
  return RolePermissions?.filter((data) => {
    return data.organization_type === organization_type;
  });
};
