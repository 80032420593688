// ** Redux **
import { IRootState } from '@redux/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type organizationTypes = {
  id: string;
  name: string;
  organization_type: string;
  address: string | null;
  city: string | null;
  country: string;
  industry: string;
  organization_id: string | null;
  postal_code: string | null;
  state: string | null;
  website?: string | null;
  is_shared_network: boolean;
  image: string | null;
};
export type userTypes = {
  id: string;
  firstname: string;
  lastname: string;
  fullname: string;
  email: string;
  organization_type: string;
  role_id: string;
  organization_id: string;
  team_id: string;
  organization: organizationTypes;
  is_prompt: boolean;
  project_id: string | null;
  is_login: boolean;
  role: {
    name: string;
    organization_type: string;
  };
  team: {
    name: string;
    image: null;
  };
  permissions: { [key: string]: string[] };
};

export type AuthSliceType = {
  isAuthenticated?: boolean;
  userRole?: string | null;
  activeRole?: string | null;
  userDetails?: Partial<userTypes | null>;
  activeDetails?: Partial<userTypes | null>;
  userOrganization?: Partial<organizationTypes | null>;
  activeOrganization?: Partial<organizationTypes | null>;
  parentOrganization?: Partial<organizationTypes | null>;
};

const initialState: AuthSliceType = {
  isAuthenticated: false,
  userRole: null,
  activeRole: null,
  userOrganization: null,
  activeOrganization: null,
  userDetails: null,
  activeDetails: null,
  parentOrganization: null,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated(state: AuthSliceType, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setCredentials(state: AuthSliceType, action: PayloadAction<AuthSliceType>) {
      const {
        activeDetails,
        activeRole,
        isAuthenticated,
        userDetails,
        userRole,
        userOrganization,
        activeOrganization,
        parentOrganization,
      } = action.payload;
      if (activeDetails) {
        state.activeDetails = {
          ...activeDetails,
          fullname: `${activeDetails?.firstname ?? ''} ${activeDetails.lastname ?? ''}`,
        };
      }
      if (activeRole) {
        state.activeRole = activeRole;
      }
      if (userRole) {
        state.userRole = userRole;
      }
      if (userOrganization) {
        state.userOrganization = userOrganization;
      }
      if (activeOrganization) {
        state.activeOrganization = activeOrganization;
      }
      if (parentOrganization) {
        state.parentOrganization = parentOrganization;
      }
      if (isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
      }
      if (userDetails) {
        state.userDetails = userDetails;
      }
    },
    setActiveOrganization(
      state: AuthSliceType,
      action: PayloadAction<organizationTypes>
    ) {
      if (action.payload) state.activeOrganization = action.payload;
    },

    setLogoutData(state: AuthSliceType) {
      state.isAuthenticated = false;
      state.userRole = null;
      state.activeRole = null;
      state.userDetails = null;
      state.activeDetails = null;
      state.userOrganization = null;
      state.activeOrganization = null;
      state.parentOrganization = null;
    },
  },
});

export const { reducer } = slice;

export const { setCredentials, setAuthenticated, setLogoutData, setActiveOrganization } =
  slice.actions;

export const getAuth = (state: IRootState) => state.auth;

export const getIsAuthenticated = (state: IRootState) => state.auth.isAuthenticated;

export const getActiveRole = (state: IRootState) => state.auth.activeRole;
export const getUserRole = (state: IRootState) => state.auth.userRole;
export const getActiveOrganizationType = (state: IRootState) =>
  state.auth.activeOrganization?.organization_type;
export const getUserOrganizationType = (state: IRootState) =>
  state.auth.userOrganization?.organization_type;
export const getActiveOrganization = (state: IRootState) => state.auth.activeOrganization;
export const getUserOrganization = (state: IRootState) => state.auth.userOrganization;
export const getParentOrganization = (state: IRootState) => state.auth.parentOrganization;
export const getActiveUserData = (state: IRootState) => state.auth.activeDetails;
export const getActiveRolePermission = (state: IRootState) =>
  state.auth.activeDetails?.permissions;
export const getUserRolePermission = (state: IRootState) =>
  state.auth.userDetails?.permissions;

export default slice;
