import Meta from '@/components/Meta';
import { useEffect, useState } from 'react';
import ConsumerTrustNetworkLogo from '@images/consumer_trust_network_logo.png';
import FullConsoleView from '@images/full console view.png';
import ConsumerSegment from '@images/consumerSegmentation.png';
import CTIScoreView from '@images/ctiScore.png';
import Cancel from '@/assets/icons/cancel';
import { ReactComponent as RightUpArrow } from '@/assets/icons/right-up-arrow.svg';
import { Button } from '@/components/button';
import { useNavigate } from 'react-router-dom';
import { ORGANIZATION_TYPES, PRIVATE_NAVIGATION } from '@/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUserData } from '@/redux/slice/authSlice';
import { getActiveUserDataApi, updateUserLoggedInStatusApi } from '../auth/services';
import { getIsLoginStatus, unsetLogin } from '@/redux/slice/tokenSlice';

const TrustNetworkPage = () => {
  const [step, setStep] = useState<number>(1);
  const dispatch = useDispatch();
  const isLoginStatus = useSelector(getIsLoginStatus);
  const activeUser = useSelector(getActiveUserData);
  const { getActiveUser } = getActiveUserDataApi();
  const { updateUserLoggedInStatus } = updateUserLoggedInStatusApi();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoginStatus && activeUser?.is_login != undefined)
      setTimeout(() => {
        if (activeUser?.is_login) {
          dispatch(unsetLogin());
          navigate(PRIVATE_NAVIGATION.home.path);
        } else if (
          !activeUser?.is_login &&
          activeUser.organization_type === ORGANIZATION_TYPES.NODE
        )
          setStep(2);
        else {
          dispatch(unsetLogin());
          getUserData();
        }
      }, 2000);
  }, [activeUser]);

  const updateSteps = () => {
    setStep(step + 1);
  };

  const skipOnboarding = async () => {
    dispatch(unsetLogin());
    navigate(PRIVATE_NAVIGATION.projects.add.path, {
      state: 'Create',
    });
    await getUserData();
  };

  const getUserData = async () => {
    await updateUserLoggedInStatus();
    await getActiveUser();
  };

  return (
    <>
      {isLoginStatus && (
        <>
          <Meta title="Trust network - Trudenty Trust Network Console" />
          {step === 1 && (
            <div className="w-full h-screen absolute opacity-100 z-50 bg-white backdrop-blur-[17px] bg-custom-gradient flex items-center justify-center cursor-progress">
              <img className="" src={ConsumerTrustNetworkLogo} alt="" />
            </div>
          )}
          {step === 2 && (
            <div className="w-full h-screen absolute opacity-100 z-50 bg-white flex items-center justify-center px-[120px] py-[84px]">
              <div className="w-[1250px] relative bg-white rounded-lg">
                <button
                  className="absolute right-0 bg-stroke p-2 rounded-full"
                  onClick={() => skipOnboarding()}
                >
                  <Cancel className="cursor-pointer h-3 w-3" color="#747678" />
                </button>

                <div>
                  <h2 className="text-lg font-medium text-grey-content-tertiary">
                    Introduction
                  </h2>

                  <div className="flex flex-col md:flex-row mt-[22px] gap-6 items-start">
                    <div className="w-1/2">
                      <img className="" src={ConsumerTrustNetworkLogo} alt="" />
                    </div>
                    <div className="w-1/2">
                      <p className="text-grey-content-secondary leading-relaxed text-xl2">
                        The Trust Network provides access to the Consumer Trust Index— a
                        trust score offering a 360-degree view of the consumer to
                        differentiate trusted consumers from high-risk consumers.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-12">
                  <img
                    width={1250}
                    height={620}
                    src={FullConsoleView}
                    alt="Full Console View"
                  />
                </div>

                <div className="flex gap-2 mt-12">
                  <Button
                    title="Continue"
                    category="1"
                    color={'dark'}
                    onClick={() => updateSteps()}
                  />
                  <Button
                    title="Learn more"
                    category="1"
                    color={'light'}
                    onClick={() => {
                      window.open(
                        'https://trudenty.notion.site/Overview-of-The-Trudenty-Trust-Network-15aa0212ddd080a4b9b9ecde01cfa581?pvs=4',
                        '_blank'
                      );
                    }}
                    className="gap-x-2.5"
                    suffixIcon={<RightUpArrow />}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className="w-full h-screen absolute opacity-100 z-50 bg-white flex items-center justify-center px-[120px] py-[84px]">
              <div className="w-[1250px] relative bg-white rounded-lg">
                <button
                  className="absolute right-0 bg-stroke p-2 rounded-full"
                  onClick={() => skipOnboarding()}
                >
                  <Cancel className="cursor-pointer h-3 w-3" color="#747678" />
                </button>

                <div>
                  <h2 className="text-lg font-medium text-grey-content-tertiary">
                    Introduction
                  </h2>

                  <div className="flex flex-col md:flex-row mt-[22px] gap-6 items-start">
                    <div className="w-1/2">
                      <p className="font-semibold text-body-black text-35 leading-42">
                        Configuring a project
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-grey-content-secondary leading-relaxed text-xl2">
                        Set up a project containing fraud risk assessments computed by
                        custom-built ML algorithms for friendly fraud prevention.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-12">
                  <img
                    width={1250}
                    height={620}
                    src={ConsumerSegment}
                    alt="Consumer Segment"
                  />
                </div>

                <div className="flex gap-2 mt-12">
                  <Button
                    title="Continue"
                    category="1"
                    color={'dark'}
                    onClick={() => updateSteps()}
                  />
                  <Button
                    title="Learn more"
                    category="1"
                    color={'light'}
                    onClick={() => {
                      window.open(
                        'https://trudenty.notion.site/Configuring-a-Project-15aa0212ddd080d1ab08e1f8f46e241d?pvs=4',
                        '_blank'
                      );
                    }}
                    className="gap-x-2.5"
                    suffixIcon={<RightUpArrow />}
                  />
                </div>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className="w-full h-screen absolute opacity-100 z-50 bg-white flex items-center justify-center px-[120px] py-[84px]">
              <div className="w-[1250px] relative bg-white rounded-lg">
                <button
                  className="absolute right-0 bg-stroke p-2 rounded-full"
                  onClick={() => skipOnboarding()}
                >
                  <Cancel className="cursor-pointer h-3 w-3" color="#747678" />
                </button>

                <div>
                  <h2 className="text-lg font-medium text-grey-content-tertiary">
                    Introduction
                  </h2>

                  <div className="flex flex-col md:flex-row mt-[22px] gap-6 items-start">
                    <div className="w-1/2">
                      <p className="font-semibold text-body-black text-35 leading-[42px]">
                        Implementing the Consumer Trust Index
                      </p>
                    </div>
                    <div className="w-1/2">
                      <p className="text-grey-content-secondary leading-relaxed text-xl2">
                        Enable your customers to calculate their Consumer Trust Index
                        (CTI) and unlock personalised shopping experiences by providing
                        consent to Open Banking via your consumer link.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-12">
                  <img
                    width={1250}
                    height={620}
                    src={CTIScoreView}
                    alt="Consumer Trust Score"
                  />
                </div>

                <div className="flex gap-2 mt-12">
                  <Button
                    title="Get started"
                    category="1"
                    color={'dark'}
                    onClick={() => skipOnboarding()}
                  />
                  <Button
                    title="Learn more"
                    category="1"
                    color={'light'}
                    onClick={() => {
                      window.open(
                        'https://trudenty.notion.site/Implementing-the-Consumer-Trust-Index-15aa0212ddd08050a630fe2add82c7e2?pvs=4',
                        '_blank'
                      );
                    }}
                    className="gap-x-2.5"
                    suffixIcon={<RightUpArrow />}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TrustNetworkPage;
