type Props = {
  color?: string;
  width?: number;
  height?: number;
  className?: string;
};

const Cancel = ({ color, width, height, className, ...props }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 11}
      height={height ?? 8}
      className={className}
      viewBox="0 0 10 10"
      {...props}
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.12846 2.05026C9.28644 1.89228 9.28625 1.62617 9.12201 1.46193L8.53827 0.878195C8.36701 0.706928 8.11063 0.711065 7.94995 0.871746L4.99998 3.82171L2.05001 0.871746C1.89203 0.713766 1.62593 0.713952 1.46169 0.878195L0.877951 1.46193C0.706684 1.6332 0.71082 1.88958 0.871502 2.05026L3.82147 5.00022L0.871502 7.95019C0.713522 8.10817 0.713708 8.37428 0.877951 8.53852L1.46169 9.12225C1.63295 9.29352 1.88933 9.28938 2.05001 9.1287L4.99998 6.17874L7.94995 9.1287C8.10793 9.28668 8.37403 9.2865 8.53827 9.12225L9.12201 8.53852C9.29328 8.36725 9.28914 8.11087 9.12846 7.95019L6.17849 5.00022L9.12846 2.05026Z"
          fill={color}
        />
      </svg>
    </svg>
  );
};
export default Cancel;
